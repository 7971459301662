@import '~@ui/styles/variables';
@import '~@ui/styles/tools';

@mixin medium-breakpoint-up {
    @media screen and (min-width: 1651px) {
        @content;
    }
}

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

.desktop-view {
    height: min(calc(100vh - 92px), 988px);

    .container {
        height: min(calc(100vh - 92px), 988px);
        padding-bottom: 370px;
    }
}

.container {
    padding: 4% 0 4% 8%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    // Used for .mobile-button-wrapper with absolute positioning
    position: relative;
}

.main-info {
    width: fit-content;
    z-index: 2;
}

.background-image {
    position: absolute;

    inset: 0;
    width: 50%;
    height: 100%;
    aspect-ratio: 1.71;
    margin-left: auto;


    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden;

    pointer-events: none;
}

.title {
    font-size: 60px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 24px;
    max-width: 25ch;

    b,
    em,
    strong {
        font-weight: 500;
        color: $color-primary;
    }

    &--skeleton {
        height: calc(60px * 1.2 * 2);
    }
}

.main-info__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 64px;

    &--skeleton {
        height: calc(20px * 1.2);
        width: 450px !important;
    }
}

.main-info__button-wrapper {
    margin-bottom: 52px;
    display: flex;
    justify-content: center;

    max-width: 243px;

    span {
        margin: 0 !important;
        padding: 0;
    }
}

.form-button {
    width: 243px;
    height: 68px;
    font-size: 20px;
    font-weight: 500;
}

.achievements {
    display: flex;
    gap: 30px;
    padding: 0;

    &--skeleton {
        width: 100%;
        min-width: 900px;
        display: flex;
        gap: 30px;

        .achievement {
            flex: 1;
            height: 40px;
        }
    }
}

.image-wrapper {
    // Must use 'relative' positioning with 'fill' prop of next 'Image' component
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 1150px;
    position: relative;
    aspect-ratio: 1.71;
    display: flex;
    justify-content: center;
    align-items: center;

    // // Override NextJS img styles to keep correct aspect ratio for image
    img {
        display: grid;
        height: unset !important;
        transform: scale(2) rotate(-5deg);
        top: 100px !important;
        bottom: -100px !important;

        left: 70px !important;
        right: -70px !important;
    }
}

.image {
    pointer-events: none;

    &--skeleton {
        width: 200px;
        height: 300px;
    }
}

.full-width {
    width: 100%;
}

.statistics-skeleton {
    position: absolute;
    bottom: 24px;

    display: flex;
    gap: 30px;
    width: 900px;

    &--item {
        height: 180px;
    }
}

@include medium-breakpoint {
    .desktop-view {
        height: auto;

        .container {
            height: auto;
        }
    }

    .container {
        padding-bottom: 0;
    }

    .title {
        font-size: 56px;

        &--skeleton {
            width: 300px;
            height: calc(56px * 1.2 * 2);
        }
    }

    .main-info__description {
        font-size: 18px;

        &--skeleton {
            height: calc(18px * 1.2);
        }
    }

    .background-image {
        width: 50%;
        align-items: flex-start;
    }

    .image-wrapper {
        max-width: 850px;
        img {
            transform: scale(1.5) rotate(-5deg);
            top: 35% !important;
            bottom: -35% !important;
    
            left: 20% !important;
            right: -20% !important;
        }
    }
}


@include small-breakpoint {
    .desktop-view {
        .container {
            padding-bottom: 270px;
        }
    }

    .main-info:not(.main-info--mobile) {
        padding-top: initial;
    }

    .title {
        font-size: 40px;

        &--skeleton {
            width: 300px;
            height: calc(40px * 1.2 * 2);
        }
    }

    .main-info__description {
        font-size: 16px;
        margin-bottom: 20px;

        &--skeleton {
            height: calc(16px * 1.2);
        }
    }

    .achievements {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-template-rows: repeat(2, max-content);
    }

    .achievement {
        &:nth-child(1) {
            grid-row: 1 / 2;
            grid-column: 1 / 2;

        }

        &:nth-child(2) {
            grid-row: 2 / 3;
            grid-column: 1 / 2;


        }

        &:nth-child(3) {
            grid-row: 1 / 2;
            grid-column: 2 / 3;
        }

        &:nth-child(4) {

            grid-row: 2 / 3;
            grid-column: 2 / 3;
        }
    }

    .background-image {
        margin-top: auto;
        margin-left: auto;
        width: 65%;
        aspect-ratio: 1.71;
    }

    .image-wrapper {
        max-width: 850px;
        img {
            transform: scale(1.2) rotate(-5deg);
            top: 50px !important;
            bottom: -50px !important;
    
            left: 200px !important;
            right: -200px !important;
        }
    }
}

@include media-tablet {
    .desktop-view {
        max-height: unset;
        .container {
            padding: 4% 16px 225px 16px;
        }
    }

    .title {
        margin-bottom: 20px;
        max-width: 15ch;

        &--skeleton {
            width: 300px;
            height: calc(40px * 1.2 * 2);
        }
    }

    .main-info__description {
        max-width: 30ch;

        &--skeleton {
            height: calc(20px * 1.2);
        }
    }

    .achievements {
        justify-content: flex-start;
        margin-top: 24px;
    }

    .image-wrapper {
        max-width: 850px;
        img {
            transform: scale(1.2) rotate(-5deg);
            top: 50px !important;
            bottom: -50px !important;
    
            left: 100px !important;
            right: -100px !important;
        }
    }
}

@include xsmall-breakpoint {
    .title {
        font-size: 36px;
        margin-bottom: 16px;

        &--skeleton {
            width: 300px;
            height: calc(36px * 1.2 * 2);
        }
    }

    .main-info__description {
        font-size: 14px;
        margin-bottom: 16px;

        &--skeleton {
            height: calc(14px * 1.2);
        }
    }

    .achievements {
        gap: 10px;
    }
}

@include media-tablet-portrait {
    .achievements {
        gap: 10px;
    }

    .background-image {
        width: 70%;
    }

    .image-wrapper {
        img {
            top: 25% !important;
            bottom: -25% !important;
    
            left: 30% !important;
            right: -30% !important;
        }
    }
}

@include media-mobile {
    .desktop-view {
        .container {
            flex-direction: column;
            padding-bottom: 215px;
            padding-right: 16px;
            overflow: hidden;
        }
    }

    .main-info {
        margin-bottom: 0;
        width: 100%;
    }

    .title {
        margin-bottom: 12px;

        &--skeleton {
            width: 300px;
            height: calc(36px * 1.2 *2);
        }
    }

    .main-info__subsection {
        max-width: 100%;
    }

    .main-info__description {
        margin-bottom: 16px;

        &--skeleton {
            height: calc(16px * 1.2);
        }
    }

    .main-info__button-wrapper {
        margin: 0 auto 32px;
        max-width: 250px;
    }

    .form-button {
        width: 250px;
        font-size: 16px;
        height: 55px;
    }

    .achievements {
        flex-wrap: wrap;
        justify-content: unset;
        gap: 7px 20px;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .achievement {
        &:nth-child(3) {
            max-width: 200px;
        }
    }

    .background-image {
        width: 100%;
        top: unset;
        bottom: unset;
        position: relative;
        overflow: visible;
        margin-top: 10%;
        margin-left: unset;
    }

    .image-wrapper {
        width: calc(100% + 16px * 2);
        margin-left: -16px;
        margin-right: -16px;
        justify-content: flex-end;
        align-items: flex-end;
        right: 0;
        img {
            transform: scale(2) rotate(-5deg);
            transform-origin: center right;

            top: 30px !important;
            bottom: -30px !important;
    
            left: 50% !important;
            right: -50% !important;
        }
    }
}

@media only screen and (max-width: 335px) {
    .achievements {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .achievement {
        &:nth-child(n) {
            grid-column: 1 / 2;
        }

        &:nth-child(1) {
            grid-row: 1 / 2;
        }

        &:nth-child(2) {
            grid-row: 2 / 3;
        }

        &:nth-child(3) {
            grid-row: 3 / 4;
        }

        &:nth-child(4) {
            grid-row: 4 / 5;
        }
    }
}

@include media-mobile-up {
    .mobile-view {
        display: none;
    }
}

@include media-tablet-portrait-up {
    .desktop-view {
        .footer {
            .achievements {
                display: none;
            }
        }
    }
}



